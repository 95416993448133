@import url("https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:wght@300;400;500;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Rubik:wght@300;400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700;800&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  scroll-behavior: smooth;
}

ul {
  list-style: none;
}

ul li:before {
  content: "✓";
}

.animate__animated.animate__fadeInLeft {
  --animate-duration: 2s;
  --animate-delay: 8s;
}

.animate__animated.animate__fadeInRight {
  --animate-duration: 2s;
  --animate-delay: 8s;
}
